<template>
  <div class="layout-news">
    <BlockHeader :links="headerLinks" />

    <!-- NewsArticleHero -->
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="extraData" class="layout-news__hero-article">
          <div
            class="layout-news__hero-article-back"
            @click="router.back()"
            @keypress="router.back()"
          >
            <IonIcon icon-name="chevron-left" />
            <p>
              {{ useStoryblokTranslation('general', 'back_to_overview') }}
            </p>
          </div>
          <AtomImage
            v-if="extraData?.image_video?.filename"
            class="layout-news__hero-article-image"
            :image="extraData.image_video"
            provider="storyblok"
            :lazy="false"
            height="518px"
            width="960px"
          />

          <div v-if="extraData?.tags.length > 0" class="layout-news__hero-article-tags">
            <p
              v-for="tag in computedTags"
              :key="tag.index"
              class="layout-news__hero-article-tag"
              :style="{
                'background-color': extraData.department.content.color_11,
                color: extraData.department.content.color_6,
              }"
            >
              {{ tag.name }}
            </p>
          </div>

          <div
            v-if="extraData?.department.name || extraData?.type.name"
            class="layout-news__hero-article-department-type"
            :style="{
              color: extraData.department.content.color_4,
            }"
          >
            <p v-if="extraData?.department.name">
              {{ computedDepartment }}
            </p>
            <p v-if="extraData?.type.name">
              |
            </p>
            <p v-if="extraData?.type.name">
              {{ computedType }}
            </p>
          </div>

          <AtomHeading
            v-if="extraData?.title"
            class="layout-news__hero-article-title"
            :class="{ 'has-subtitle': extraData.sub_title }"
            :text="extraData.title"
            html-tag="h2"
            font-size="h2"
          />

          <AtomHeading
            v-if="extraData?.sub_title"
            class="layout-news__hero-article-subtitle"
            :text="extraData.sub_title"
            html-tag="h2"
            font-size="h2"
          />

          <div class="layout-news__hero-article-details">
            <p>
              {{ dayjs(firstPublishedAt).locale(currentLocale).format('DD. MMMM YYYY') }}
            </p>
            <p v-if="extraData.author">
              |
            </p>
            <span
              v-if="extraData.author"
              class="layout-news__hero-article-details-author"
              :style="`--c-department-4: ${extraData.department.content.color_4};`"
            >
              {{ extraData.author.name }}
            </span>
            <div class="layout-news__hero-article-details-share">
              <div
                @click="share()"
                @keypress="share()"
              >
                <IonIcon
                  icon-name="share"
                />
              </div>
              <ClientOnly>
                <AtomSocialShare
                  v-for="network in networks"
                  :key="network.network"
                  :network="network.network"
                  :url="baseUrl + route.fullPath"
                  :title="extraData.title"
                  :description="extraData.sub_title"
                  :image="extraData.image_video.filename"
                >
                  <IonIcon
                    :icon-name="network.network"
                  />
                </AtomSocialShare>
              </ClientOnly>
            </div>
          </div>

          <AtomLead
            v-if="extraData?.lead"
            class="layout-news__hero-article-lead"
            :text="extraData.lead"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <slot />

    <UtilWrapperLayout class="layout-news__similar-article-wrapper">
      <UtilGrid class="layout-news__grid">
        <div class="layout-news__similar-article is-wide">
          <div class="layout-news__similar-article-head">
            <AtomHeading
              class="layout-news__similar-article-title"
              :text="useStoryblokTranslation('general', 'similar_articles')"
              html-tag="h3"
              font-size="h3"
            />
            <div class="layout-news__controls">
              <IonIcon
                v-if="!isBeginning"
                icon-name="chevron-left"
                @click="changeSlide('prev')"
              />
              <p>
                {{ activeIndex }}/{{ totalSlides }}
              </p>
              <IonIcon
                v-if="activeIndex < totalSlides"
                icon-name="chevron-right"
                @click="changeSlide('next')"
              />
            </div>
          </div>
        </div>
        <div class="layout-news__wrapper is-wide">
          <div class="layout-news__similar-article-swiper-wrapper">
            <Swiper
              :slides-per-view="clientDeviceStore.isMobile ? 'auto' : 2.8"
              :space-between="20"
              :grab-cursor="true"
              class="layout-news__swiper"
              @swiper="onSwiper"
              @slide-change="onSlideChange"
            >
              <SwiperSlide
                v-for="article in similarArticles"
                :key="article._uid"
                class="layout-news__swiper-card"
              >
                <BlockItemTeaser
                  :item="{ ...article }
                  "
                  news-section
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="newsletterForm"
    />
    <BlockFooter v-bind="footer" />
  </div>
</template>

<script setup>
const props = defineProps({
    headerLinks: {
        type: Array,
        default: () => [],
    },

    footer: {
        type: Object,
        default: () => {},
    },

    serviceMenu: {
        type: Object,
        default: () => {},
    },

    newsletterForm: {
        type: Object,
        default: () => {},
    },

    extraData: {
        type: Object,
        default: () => {},
    },

    firstPublishedAt: {
        type: String,
        required: true,
    },
});

const {
    extraData,
} = toRefs(props);

/*
    Variables
*/
const { dayjs } = useDayJs();
const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const baseUrl = computed(() => runtimeConfig.public.BASE_URL);
const strokeColor = computed(() => props.extraData?.department?.content?.color_4);

const networks = [
    { network: 'linkedin', icon: ['linkedin'] },
];

/*
    Functions
*/
const share = () => {
    // share url mobile
    if (navigator.share) {
        navigator.share({
            title: props.extraData.title,
            text: props.extraData.sub_title,
            url: baseUrl + route.fullPath,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    } else {
        // share url desktop
        window.location.href = `mailto:?subject=${props.extraData.title}&body=${props.extraData.sub_title}%0D%0A%0D%0A${baseUrl.value + route.fullPath}`;
    }
};

/*
    Init Swiper
*/
const { Swiper, SwiperSlide } = useSwiperjs();
const swiperController = ref(null);
const totalSlides = ref(0);
const activeIndex = ref(1);
const isBeginning = ref(true);
const isEnd = ref(false);
const clientDeviceStore = useClientDeviceStore();

const onSwiper = (e) => {
    swiperController.value = e;
};

// watch swiperController to init totalSlides
watchEffect(() => {
    if (swiperController.value) {
        totalSlides.value = swiperController.value.snapGrid.length - 1;

        if (clientDeviceStore.isMobile) {
            totalSlides.value += 1;
        }
    }
});

const slideFromEnd = () => {
    if (isEnd.value && !clientDeviceStore.isMobile) {
        isEnd.value = false;
        swiperController.value.slideTo(totalSlides.value - 2);
    }
};

const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        if (isEnd.value && !clientDeviceStore.isMobile) {
            slideFromEnd();
        } else {
            swiperController.value.slidePrev();
        }
    }
};

const onSlideChange = (slide) => {
    activeIndex.value = slide.realIndex + 1;
    isBeginning.value = slide.isBeginning;

    if (!isEnd.value && activeIndex.value === totalSlides.value && !clientDeviceStore.isMobile) {
        // slide to the last slide + 1 to show the last slide
        swiperController.value.slideTo(totalSlides.value);
        isEnd.value = true;
    }
};

const storyblokApi = useStoryblokApi();
const similarArticles = ref([]);
const storyStore = useStoryStore();
const currentStoryId = computed(() => storyStore.getCurrentStoryId);
const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getSimilarArticles = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article/',
            version: 'published',
            sort_by: 'first_published_at:desc',
            per_page: 5,
            filter_query: {
                department: {
                    in: extraData.value.department.uuid,
                },
            },
            excluding_ids: currentStoryId.value,
            resolve_relations: 'News.department,News.tags,News.author,News.type',
            language,
        });

        if (!Array.isArray(data.stories)) {
            throw new Error('Unexpected response from the server');
        }

        // map similar articles
        similarArticles.value = data.stories.map((article) => {
            const department = article.content.department || {};
            const imageVideo = article.content.image_video || {};

            return {
                article: {
                    title: article.content.title,
                    url: article.default_full_slug,
                    date: article.first_published_at,
                    department: {
                        name: department.translated_slugs?.find(
                            (slug) => slug.lang === currentLocale.value,
                        )?.name || department?.name,
                        color_3: department.content?.color_3,
                    },
                    image: {
                        ...imageVideo,
                        provider: 'storyblok',
                    },
                },
                size: 'small',
            };
        });

        // reset swiper
        swiperController.value.slideTo(0);
    } catch (e) {
        useSentryError(e);
    }
};

useLazyAsyncData(
    'similar-articles',
    getSimilarArticles,
    {
        immediate: true,
        server: false,
        watch: [extraData],
    },
);

const computedTags = computed(() => extraData.value?.tags?.map((tag) => ({
    ...tag,
    translated_slugs: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value),
    name: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value)?.name || tag.name,
})));

const computedType = computed(() => extraData.value?.type?.translated_slugs?.find(
    (slug) => slug.lang === currentLocale.value,
)?.name || extraData.value?.type.name);

const computedDepartment = computed(() => extraData.value?.department?.translated_slugs?.find(
    (slug) => slug.lang === currentLocale.value,
)?.name || extraData.value?.department.name);
</script>
<style lang="scss">
.layout-news {
    position: relative;
    background-color: var(--c-background);
}

.layout-news__hero-article {
    @include default-content-columns;

    margin-top: 78px;
    margin-bottom: 33px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-news__hero-article-details-share {
    display: flex;
    column-gap: 29px;

    path {
        stroke: v-bind(strokeColor);
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news__controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        margin-top: 6px;

        @include mobile {
            margin-top: 1px;
        }
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news__swiper {
    display: flex;

    @include tablet {
        display: flex;
        overflow: visible;
        flex-direction: row;

        .layout-news__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;
        }

        .swiper-slide {
            width: 270px !important;
        }
    }
}

.layout-news__hero-article-back {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 10px;
    color: var(--c-petrol-4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    p {
        padding-top: 1px;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}

.layout-news__hero-article-image {
    @include fluid('margin-bottom', 10px, 19px);

    height: 518px;

    @include mobile {
        height: 152px;
    }
}

.layout-news__hero-article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;

    @include mobile{
        margin-bottom: 20px;
    }
}

.layout-news__hero-article-tag {
    padding: 6px 12px;
    border-radius: 4px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__hero-article-department-type {
    @include fluid('margin-bottom', 5px, 10px);

    display: flex;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--ri--f-size--richtext);
}

.layout-news__hero-article-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    row-gap: 5px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-news__hero-article-title {
    @include fluid('margin-bottom', 20px, 30px);

    &.has-subtitle {
        margin-bottom: 0;
    }
}

.layout-news__hero-article-subtitle {
    @include fluid('margin-bottom', 20px, 30px);
}

.layout-news__similar-article-wrapper {
    overflow: hidden;
}

.layout-news__similar-article {
    @include default-content-columns;

    display: flex;
    flex-direction: column;
}

.layout-news__grid {
    margin-bottom: 72px;

    &.block-grid {
        row-gap: 20px;
    }
}

.layout-news__similar-article-head {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 30px;
    background-color: var(--c-white);
    color: v-bind(colorMain);
}

.layout-news__similar-article-title {
    &.atom-heading {
        font-family: var(--f-family--black);
    }
}

.layout-news__similar-article-swiper-wrapper {
    width: 100%;
}

.layout-news__wrapper {
    @include default-content-columns;
}

.layout-news__imgWrapper {
    overflow: hidden;

    img {
        height: 201px;
    }
}

.layout-news__tag {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__video-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 92px;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__date {
    margin-top: 10.87px;
    color: v-bind(colorDate);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.layout-news__lead {
    p {
        margin-bottom: 20px;
    }
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__type {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__link {
    padding-top: 20px;
}

.layout-news__icon {
    transition: all 0.2s ease-out;
}

.layout-news__img {
    transition: transform 0.2s ease-out;
}

.layout-news__similar-article-wrapper-item {
    &:hover {
        .layout-news__img {
            transform: scale(1.05);
        }

        .layout-news__video-tag-icon {
            transform: none;
        }

        .layout-news__icon {
            transform: translateX(10px);
        }
    }
}

.layout-news__hero-article-details-author {
    color: var(--c-department-4);
}
</style>
